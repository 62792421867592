<template lang="pug">
div
  Form(:medical="row" @save="updateData")
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    row: { type: Object, default: () => ({}) }
  },
  components: {
    Form: () => import('./Form.vue')
  },
  methods: {
    ...mapActions(['updatePriceForMedicalInstitution']),
    updateData (body) {
      this.updatePriceForMedicalInstitution({ id: this.row.id, body })
    }
  }
}
</script>
